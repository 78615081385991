import { Home as HomeIcon, Menu } from '@mui/icons-material'
import React from 'react'

const timRoutes = [
    {
        type: "collapse",
        name: "Dashboard",
        key: [''],
        route: "/tim",
        icon: <HomeIcon size="12px" />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Pendataan",
        key: ['pendataan'],
        route: "/tim/pendataan",
        icon: <Menu size="12px" />,
        noCollapse: true,
    },
    // {
    //     type: "collapse",
    //     name: "Tables",
    //     key: ['default', 'automotive'],
    //     icon: <Build size="12px" />,
    //     collapse: [
    //         {
    //             name: "Default",
    //             key: "/admin/default",
    //             route: "/admin/default",
    //             component: <Dashboard />,
    //         },
    //         {
    //             name: "Automotive",
    //             key: "/admin/automotive",
    //             route: "/admin/automotive",
    //             component: <Dashboard />,
    //         },
    //     ],
    // },
    // {
    //     type: "collapse",
    //     name: "User",
    //     key: ['tim', 'admin', 'kabupaten'],
    //     icon: <Person size="12px" />,
    //     collapse: [
    //         {
    //             name: "Tim",
    //             key: "/admin/tim",
    //             route: "/admin/tim",
    //             component: <TimList />,
    //         },
    //         {
    //             name: "Admin",
    //             key: "/admin/admin",
    //             route: "/admin/admin",
    //             component: <UserList />,
    //         },
    //         {
    //             name: "Kabupaten",
    //             key: "/admin/kabupaten",
    //             route: "/admin/kabupaten",
    //             component: <Kabupaten />,
    //         },
    //     ],
    // }
]

export default timRoutes