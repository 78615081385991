import { Card, CardContent, Grid } from '@mui/material'
import axios from 'axios'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import MapComponent from 'myComponent/MapComponent'
import React, { useEffect, useState } from 'react'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const Dashboard = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [data, setData] = useState([])

    useEffect(() => {
        reqDataPendataan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reqDataPendataan = () => {
        axios.post(
            serverUrl + '/tim/pendataan',
            {
                semua: true
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setData(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }
    return (
        <>
            <Headerx title="Dashboard" />
            <SoftBox mb={3}>
                {/* <Grid container spacing={3} p={5} justifyContent='center'>
                    <Grid item xs={12} sm={12} md={9}>
                        <SoftTypography variant='h5'>
                            Detail Data
                        </SoftTypography>
                    </Grid>
                </Grid> */}
                <Grid container spacing={3} p={5} justifyContent='center'>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            {/* <CardHeader
                                title={
                                    <Typography variant='h6'>Kembali</Typography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            // subheader="September 14, 2016"
                            /> */}
                            <CardContent>
                                <MapComponent data={data} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Dashboard