import { Divider, List, ListItem, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'
import { serverUrl } from '../global/variable';
import { CustomDescrypt } from '../global/func';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';

const DetailKeluarga = ({ data }) => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [anggota, setAnggota] = useState([]);

    useEffect(() => {
        reqAnggota()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reqAnggota = () => {
        axios.post(
            serverUrl + '/anggota-keluarga',
            {
                no_kk: data.no_kk
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setAnggota(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        No. KK
                    </Typography>
                } secondary={
                    <Typography variant='body2'>
                        {data.no_kk}
                    </Typography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        Suku
                    </Typography>
                } secondary={
                    <Typography variant='body2'>
                        {data.suku}
                    </Typography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        Pekerjaan
                    </Typography>
                } secondary={
                    <Typography variant='body2'>
                        {data.pekerjaan}
                    </Typography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        Alamat
                    </Typography>
                } secondary={
                    <>
                        <Typography variant='body2' component="span" sx={{ display: 'block' }} color="text.primary">
                            {data.alamat}
                        </Typography>
                        Kabupaten {data.nama_kab}, Kecamatan {data.nama_kec}, {data.nama_desa}, {data.nama_dusun}
                    </>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        No. HP
                    </Typography>
                } secondary={
                    <Typography variant='body2'>
                        {data.no_hp}
                    </Typography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        BPJS
                    </Typography>
                } secondary={
                    data.kelas_bpjs === '-' ? (
                        <Typography variant='body2'>
                            Tidak ada
                        </Typography>
                    ) : (
                        <Typography variant='body2'>
                            Kelas {data.kelas_bpjs}
                        </Typography>
                    )
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        Listrik
                    </Typography>
                } secondary={
                    data.pln === '-' ? (
                        <Typography variant='body2'>
                            Tidak ada
                        </Typography>
                    ) : (
                        <>
                            <Typography variant='body2' component="span" sx={{ display: 'block' }} color="text.primary">
                                {data.pln} WATT
                            </Typography>
                            Nomor Meteran ({data.no_pln})
                        </>
                    )
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        PDAM
                    </Typography>
                } secondary={
                    data.pengguna_pdam === '-' ? (
                        <Typography variant='body2'>
                            Tidak ada
                        </Typography>
                    ) : (
                        <>
                            <Typography variant='body2' component="span" sx={{ display: 'block' }} color="text.primary">
                                {data.pengguna_pdam}
                            </Typography>
                            Biaya Rp.{new Intl.NumberFormat().format(data.pemakaian_pdam)}
                        </>
                    )
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        Penerima Bantuan
                    </Typography>
                } secondary={
                    <Typography variant='body2'>
                        {data.bantuan}
                    </Typography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        Jumlah Anggota Keluarga
                    </Typography>
                } secondary={
                    <Typography variant='body2'>
                        {data.jml_anggota} Orang
                    </Typography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>NIK</TableCell>
                                <TableCell>nama</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {anggota.map((row, index) => (
                                <TableRow
                                    key={row.nik}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        {index+1}
                                    </TableCell>
                                    <TableCell>{row.nik}</TableCell>
                                    <TableCell>{row.nama}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ListItem>
            <Divider variant="inset" component="li" />
        </List>
    )
}

export default DetailKeluarga