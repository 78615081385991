import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Card, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { Link, Route, Routes } from 'react-router-dom';
import { AddCircleOutline } from '@mui/icons-material';
import SoftBox from 'components/SoftBox';
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard';
import { serverUrl } from 'global/variable';
import Swal from 'sweetalert2';
import { CustomDescrypt } from 'global/func';
import userStore from 'store/userStore';
import Tambah from './Tambah';
import SoftTypography from 'components/SoftTypography';
import SelectKab from 'myComponent/SelectKab';
import SelectKec from 'myComponent/SelectKec';
import SelectDesa from 'myComponent/SelectDesa';
import TableData from 'myComponent/TableData';

const Tim = () => {
    return (
        // <SoftBox>
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='tambah' element={<Tambah />} />
        </Routes>
        // </SoftBox>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [tims, setTim] = useState([]);
    const [deleting, setDeleting] = useState(false)

    const [formData, setFormData] = useState({
        semua: false,
        id_kab: '17',
        id_kec: '',
        id_desa: ''
    })

    useEffect(() => {
        getTim();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, deleting]);

    const getTim = async () => {
        axios.post(
            serverUrl + '/admin/tim',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setTim(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tims.length}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Card >
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs="auto">
                                <SoftTypography>
                                    Tabel Tim
                                </SoftTypography>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    endIcon={<AddCircleOutline color='white' />}
                                    to="/admin/tim/tambah"
                                    component={Link}
                                >
                                    Tambah
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container p={2} spacing={2} alignItems='flex-end'>
                            <Grid item xs={12} sm={6} lg={3}>
                                <SelectKab formData={formData} setFormData={setFormData} disabled={true} />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <SelectKec formData={formData} setFormData={setFormData} idKab={formData.id_kab} disabled={formData.semua === true} />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <SelectDesa formData={formData} setFormData={setFormData} idKec={formData.id_kec} disabled={formData.semua === true} />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={formData.semua} onChange={(event) => setFormData({ ...formData, semua: event.target.checked })} />} label="Tampilkan Semua" disabled />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs={12}>
                                <TableData
                                    tableData={tims}
                                    headers={['Username', 'Nama', 'Password', 'Kabupaten', 'Kecamatan', 'Desa']}
                                    fields={['username', 'nama', 'password', 'nama_kab', 'nama_kec', 'nama_desa']}
                                    deleting={deleting}
                                    setDeleting={setDeleting}
                                    deleteUrl={serverUrl + '/admin/tim/delete'}
                                    deleteField='username'
                                    editUrl={'/edit'}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}


export default Tim
