import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './assets/theme';
import { Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import TemplateAdmin from './pages/admin/TemplateAdmin';
import TemplateTim from 'pages/tim/TemplateTim';

function App() {
    // const { pathname } = useLocation();

    // useEffect(() => {
    //     document.documentElement.scrollTop = 0;
    //     document.scrollingElement.scrollTop = 0;
    // }, [pathname]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
                <Route exact path='/tim/*' element={<TemplateTim />} />
                <Route exact path='/admin/*' element={<TemplateAdmin />} />
                <Route exact path='/' element={<Home />} />
            </Routes>
        </ThemeProvider>
    );
}

export default App;
