import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import drilldown from "highcharts/modules/drilldown.js";
import { useEffect } from 'react';

require("highcharts/modules/exporting")(Highcharts);
drilldown(Highcharts)

const BarDrilldown = ({series = [], drilldown =[{}]}) => {

    useEffect(() => {
        if (series.length > 1) {
            console.log(series[0]);
        }
    }, [series])

    const options = {
        chart: {
            type: 'column'
        },
        style: {
            // fontFamily: 'serif',
            color: 'red',
        },
        title: {
            align: 'center',
            text: 'Pendataan Per Kecamatan',
            // margin: 35
        },
        subtitle: {
            align: 'center',
            text: 'Klik kecamatan untuk melihat Data Kelurahan'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category',
        },
        yAxis: {
            title: {
                text: 'Terdata'
            },
            gridLineWidth: 0,
            // lineColor: 'transparent',
            // minorTickLength: 0,
            // tickLength: 0
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: true,
        },
        navigation: {
            buttonOptions: {
                align: 'right',
                verticalAlign: 'top',
                y: 0
            }
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                // pointWidth: 25,
                borderRadius: 5,
                dataLabels: {
                    enabled: true,
                    // format: '{point.y:.1f}%'
                    format: '{point.y}',
                }
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>'
        },
        series: [
            {
                name: 'Kecamatan',
                colorByPoint: true,
                data: series
            },
        ],
        drilldown: {
            breadcrumbs: {
                position: {
                    align: 'right'
                }
            },
            activeAxisLabelStyle: {
                textDecoration: 'none',
                color: 'black',
                // fontStyle: 'italic',
                fontWeight: 'normal'
            },
            activeDataLabelStyle: {
                textDecoration: 'none',
                color: 'black',
                // fontWeight: 'normal'
            },
            series: drilldown
        }
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default BarDrilldown