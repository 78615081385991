import React, { useEffect, useState } from 'react'
import SoftBox from '../../components/SoftBox'
import { Button, Card, CardContent, Grid, Tooltip, Typography } from '@mui/material'
import Headerx from 'components/Header/Headerx';
import InfoCard from 'myComponent/Cards/InfoCard';
import { LocationOnOutlined, PeopleAltOutlined, ShareLocationOutlined } from '@mui/icons-material';
import BarDrilldown from 'myComponent/Charts/BarDrilldown';
import ActivityGaugeChart from 'myComponent/Charts/ActivityGaugeChart';
import PieProgres from 'myComponent/Charts/PieProgres';
import MapComponent from 'myComponent/MapComponent';
import { CustomDescrypt } from 'global/func';
import userStore from 'store/userStore';
import axios from 'axios';
import { serverUrl } from 'global/variable';
import Swal from 'sweetalert2';
import PieStandar from 'myComponent/Charts/PieStandar';

const Dashboard = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [data, setData] = useState([])
    const [bpjsPie, setBpjsPie] = useState([])
    const [plnPie, setPlnPie] = useState([])
    const [barDrilldown, setBarDrilldown] = useState([])
    const [barSeries, setBarSeries] = useState([])

    useEffect(() => {
        reqDataPendataan()
        getDataPieChartBpjs()
        getDataPieChartPln()
        getBarDrilldown()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reqDataPendataan = () => {
        axios.post(
            serverUrl + '/admin/pendataan',
            {
                semua: true
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setData(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataPieChartBpjs = () => {
        axios.post(
            serverUrl + '/admin/bpjs',
            {
                id_kab: '17',
                id_kec: '',
                id_desa: ''
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setBpjsPie(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getDataPieChartPln = () => {
        axios.post(
            serverUrl + '/admin/pln',
            {
                id_kab: '17',
                id_kec: '',
                id_desa: ''
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setPlnPie(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const getBarDrilldown = () => {
        axios.post(
            serverUrl + '/admin/bar-drilldown',
            {
                id_kab: '17'
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setBarDrilldown(response.data.drilldown)
                setBarSeries(response.data.series)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    return (
        <>
            <SoftBox>
                <Headerx title="Dashboard" />
                <SoftBox my={3}>
                    <Grid container spacing={2} alignItems="stretch">
                        <Grid item xs={12} sm={12} xl={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <InfoCard
                                        title={{ text: "Kecamatan" }}
                                        icon={<LocationOnOutlined fontSize='medium' color='warning' sx={{ opacity: 0.3, fontSize: 90 }} />}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <InfoCard
                                        title={{ text: "Kelurahan" }}
                                        icon={<ShareLocationOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90, color: '#0097a7' }} />}
                                        count={65}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <InfoCard
                                        title={{ text: "Tim Pendataan" }}
                                        icon={<PeopleAltOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='error' />}
                                        count={62}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} pt={2}>
                                <Grid item xs={12} lg={12}>
                                    <Card sx={{ p: 1 }}>
                                        <BarDrilldown series={barSeries} drilldown={barDrilldown} />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} pt={2}>
                                {
                                    bpjsPie.map((data) => (
                                        <Grid item xs={12} md={6}>
                                            <Card>
                                                <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                                    <Grid item xs={9}>
                                                        <Typography variant='h5' fontWeight='700'>
                                                            {data.name === 'Tidak Ada' ? 'Tidak ada BPJS' : 'BPJS ' + data.name}
                                                        </Typography>
                                                        <Typography variant='body2' color='GrayText'>
                                                            {data.y} KK
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} textAlign='center' pt={1}>
                                                        <PieProgres value={data.y} color={data.color} />
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    ))
                                }
                                <Grid item xs={12}>
                                    <Card sx={{ p: 1 }}>
                                        <PieStandar title="Pengguna BPJS" data={bpjsPie} />
                                    </Card>
                                </Grid>
                                {
                                    plnPie.map((data) => (
                                        <Grid item xs={12} md={4}>
                                            <Card>
                                                <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                                    <Grid item xs={7}>
                                                        <Typography variant='h5' fontWeight='700'>
                                                            {data.name === 'Tidak Ada' ? 'Tidak ada' : data.name}
                                                        </Typography>
                                                        <Typography variant='body2' color='GrayText'>
                                                            {data.y} KK
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5} textAlign='center' pt={1}>
                                                        <PieProgres value={data.y} color={data.color} />
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    ))
                                }
                                <Grid item xs={12}>
                                    <Card sx={{ p: 1 }}>
                                        <PieStandar title="PLN" data={plnPie} />
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card sx={{ p: 1 }}>
                                        <PieStandar title="PDAM" />
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card sx={{ p: 1 }}>
                                        <PieStandar title="Bantuan" />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} pt={2}>
                                <Grid item xs={12} lg={12}>
                                    <Card >
                                        <CardContent sx={{ mt: 2 }}>
                                            <MapComponent data={data} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} xl={4}>
                            <Grid container spacing={1.5}>
                                <Grid item xs={12}>
                                    <Card sx={{ p: 1 }}>
                                        <ActivityGaugeChart
                                            title='Kota Kendari'
                                            exporting={true}
                                            tooltip={true}
                                        />
                                        <Grid container>
                                            <Grid item xs={4} textAlign='center'>
                                                <Typography variant='caption' color='GrayText'>
                                                    Total DPT
                                                </Typography>
                                                <Typography variant='h5' fontWeight='700'>
                                                    10.000
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} textAlign='center'>
                                                <Typography variant='caption' color='GrayText'>
                                                    Target
                                                </Typography>
                                                <Typography variant='h5' fontWeight='700'>
                                                    7.931
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} textAlign='center'>
                                                <Typography variant='caption' color='GrayText'>
                                                    Tercapai
                                                </Typography>
                                                <Typography variant='h5' fontWeight='700'>
                                                    5.098
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Abeli
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 143 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={34} color='#279DFE' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Baruga
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 260 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={68} color='#4136B9' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Kadia
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 154 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={26} color='#1EE15E' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Kambu
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 298 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={39} color='#fe6a35' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Kambu
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 298 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={39} color='#fe6a35' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Kambu
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 298 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={39} color='#fe6a35' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Kambu
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 298 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={39} color='#fe6a35' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Kambu
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 298 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={39} color='#fe6a35' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Kambu
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 298 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={39} color='#fe6a35' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Kambu
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 298 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={39} color='#fe6a35' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row" px={2}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5' fontWeight='700'>
                                                    Kambu
                                                </Typography>
                                                <Typography variant='body2' color='GrayText'>
                                                    Terdata 298 (Total XXX)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={39} color='#fe6a35' />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Tooltip title="fsdfs" placement="left">
                                        <Button variant="contained" fullWidth color='secondary' >Selengkapnya</Button>
                                    </Tooltip>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>


            </SoftBox>
        </>
    )
}

export default Dashboard