import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Custom styles for the SidenavCollapse
import {
    collapseItem,
    collapseIconBox,
    collapseIcon,
    collapseText,
} from "./styles/sidenavCollapse";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function SidenavCollapse({ color, icon, name, children, active, noCollapse, open, ...rest }) {
    const [controller] = useSoftUIController();
    const { miniSidenav, transparentSidenav } = controller;
    // console.log(children);

    return (
        <>
            <ListItem component="li">
                <SoftBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav })}>
                    <ListItemIcon
                        sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, color })}
                    >
                        {typeof icon === "string" ? (
                            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
                        ) : (
                            icon
                        )}
                    </ListItemIcon>

                    <ListItemText
                        primary={name}
                        sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
                    />
                    {!miniSidenav && (children && (open ? <ExpandLess /> : <ExpandMore />))}
                </SoftBox>
            </ListItem>
            {children && (
                <Collapse in={open} unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
    color: "info",
    active: false,
    noCollapse: false,
    children: false,
    open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
    active: PropTypes.bool,
    noCollapse: PropTypes.bool,
    open: PropTypes.bool,
};

export default SidenavCollapse;
