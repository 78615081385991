import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";

require("highcharts/modules/exporting")(Highcharts);
highchartsMore(Highcharts);
solidGauge(Highcharts);

const ActivityGaugeChart = ({title, exporting = true, tooltip = true }) => {

    const options = {
        chart: {
            type: 'solidgauge',
            height: '110%',
        },
        title: {
            align: 'center',
            text: title,
            // margin: 35
        },
        exporting: {
            enabled: exporting
        },
        tooltip: {
            enabled: tooltip,
            borderWidth: 0,
            backgroundColor: 'none',
            shadow: false,
            style: {
                fontSize: '1rem'
            },
            positioner: function (labelWidth) {
                return {
                    x: (this.chart.chartWidth - labelWidth) / 2,
                    y: (this.chart.plotHeight / 2) + 5
                };
            },
            useHTML: true,
            formatter: function () {
                return '<table><tr><td style="text-align: center;">' + this.series.name + '</td></tr><tr><td style="text-align: center; font-size:1.5rem; color: ' + this.point.color + '; font-weight: bold"">' + this.point.total + '</td></tr><tr><td>(' + this.point.y + '% ' + this.point.capt + ')</td></tr></table>'
            }
        },
        pane: {
            startAngle: 0,
            endAngle: 360,
            background: [{ // Track for Move
                outerRadius: '100%',
                innerRadius: '81%',
                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0.3)
                    .get(),
                borderWidth: 0
            }, { // Track for Exercise
                outerRadius: '80%',
                innerRadius: '61%',
                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
                    .setOpacity(0.3)
                    .get(),
                borderWidth: 0
            }]
        },
        credits: {
            enabled: false
        },
        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: []
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true,
            },
            // series: {
            //     borderRadius: 20,
            // }
        },
        series: [{
            name: 'Tercapai',
            data: [{
                color: Highcharts.getOptions().colors[0],
                radius: '100%',
                innerRadius: '81%',
                y: 43,
                total: 5098,
                capt: 'dari Target'
            }]
        }, {
            name: 'Target',
            data: [{
                color: Highcharts.getOptions().colors[1],
                radius: '80%',
                innerRadius: '61%',
                y: 65,
                total: 7931,
                capt: 'dari total DPT'
            }]
        }]
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default ActivityGaugeChart