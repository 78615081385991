import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const userStore = create(
    persist(
        (set, get) => ({
            user: null,
            setUser: (data) => set({ user: data }),
            logOut: () => set({
                user: null
            })
            // logOut: () => {localStorage.clear()}
        }),
        {
            name: 'AuthPersist',
            getStorage: () => localStorage,
            // serialize: (state) => btoa(JSON.stringify(state))
        }
    )
)

export default userStore