/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";

// Soft UI Dashboard React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { primary } = colors;

const select = {
    styleOverrides: {
        select: {
            display: "block",
            alignItems: "center",
            padding: `0 ${pxToRem(12)} !important`,
            // padding: `0 !important`,
            // backgroundColor: primary.main,
            minWidth: "100% !important",

            "& .Mui-selected": {
                backgroundColor: primary.main,
                width: "max-content !important",
            },
        },

        selectMenu: {
            background: "none",
            height: "none",
            minHeight: "none",
            overflow: "unset",
        },

        icon: {
            display: "block",
        },
    },
};

export default select;
