import { GoogleMap, InfoWindow, LoadScript, Marker } from "@react-google-maps/api";
import { useState } from "react";

const MapComponent = ({data = []}) => {
    const [activeInfoWindow, setActiveInfoWindow] = useState("");
    // const [markers, setMarkers] = useState(initialMarkers);
    // let bound = new window.google.maps.LatLngBounds();

    const containerStyle = {
        width: "100%",
        height: "400px",
    }

    const center = {
        lat: 28.626137,
        lng: 79.821603,
    }

    const mapClicked = (event) => {
        console.log(event.latLng.lat(), event.latLng.lng())
    }

    const markerClicked = (marker, index) => {
        setActiveInfoWindow(index)
        console.log(marker, index)
    }

    const markerDragEnd = (event, index) => {
        console.log(event.latLng.lat())
        console.log(event.latLng.lng())
    }

    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        data.forEach((position) => {
            // console.log(position.lat);
            bounds.extend({ lat: parseFloat(position.lat), lng: parseFloat(position.longi) });
        })
        
        map.fitBounds(bounds);
    };

    return (
        <LoadScript googleMapsApiKey='AIzaSyAz0Xz4Nancd2_W21MMQtq5BjG-8OZul2g'>
            <GoogleMap
                mapContainerStyle={containerStyle}
                // center={center} 
                zoom={15}
                onClick={mapClicked}
                onLoad={handleOnLoad}
                // onLoad={(map) => {
                //     const bounds = new window.google.maps.LatLngBounds();
                //     data.forEach((xx) => {
                //         bounds.extend({ lat: parseFloat(xx.lat), lng: parseFloat(xx.longi) })
                //     })
                //     map.fitBounds(bounds)
                //     // map.setMapTypeId('moon');
                // }}
                options={{
                    mapTypeControl: true,
                    streetViewControl: false,
                    draggable: true, // make map draggable
                    zoomControlOptions: { position: 9 },
                    keyboardShortcuts: false, // disable keyboard shortcuts
                    scaleControl: true, // allow scale controle
                    scrollwheel: true, // allow scroll wheel
                    //styles: mapsStyle,// change default map styles,
                }}
            >
                {data.map((data, index) => (
                    <Marker
                        key={index}
                        position={{ lat: parseFloat(data.lat), lng: parseFloat(data.longi) }}
                        label={index + ''}
                        draggable={false}
                        onDragEnd={event => markerDragEnd(event, index)}
                        onClick={event => markerClicked(data, index)}
                    >
                        {
                            (activeInfoWindow === index)
                            &&
                            (<InfoWindow position={{ lat: parseFloat(data.lat), lng: parseFloat(data.longi) }}>
                                <b>{data.lat}, {data.longi}</b>
                            </InfoWindow>)
                        }
                    </Marker>
                ))}
            </GoogleMap>
        </LoadScript>
    );
};

export default MapComponent;