import { Card, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { CustomDescrypt } from "global/func";
import { serverUrl } from "global/variable";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import userStore from "store/userStore";
import Swal from "sweetalert2";
import Detail from "./Detail";
import SelectKab from "myComponent/SelectKab";
import SelectKec from "myComponent/SelectKec";
import SelectDesa from "myComponent/SelectDesa";
import TableData from "myComponent/TableData";

const Pendataan = () => {
    return (
        <SoftBox py={3}>
            <Routes>
                <Route exact path='' element={<Data />} />
                <Route exact path='detail' element={<Detail />} />
                {/* <Route exact path='tambah' element={<Tambah />} />
                <Route exact path='edit' element={<Edit />} /> */}
            </Routes>
        </SoftBox>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [tableData, setTableData] = useState([]);
    const tanggal = dayjs(new Date());
    const [deleting, setDeleting] = useState(false)

    const [formData, setFormData] = useState({
        semua: false,
        tanggal: tanggal,
        id_kab: '17',
        id_kec: '',
        id_desa: ''
    })

    useEffect(() => {
        requestData()
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, deleting])

    const requestData = () => {
        const form = {
            semua: formData.semua,
            tgl: formData.tanggal.format('YYYY-MM-DD'),
            kab: formData.id_kab,
            kec: formData.id_kec,
            desa: formData.id_desa
        }
        axios.post(
            serverUrl + '/admin/pendataan',
            form,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tableData.length}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Card variant='outlined' spacing={3}>
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs="auto">
                                <Typography>
                                    Tabel Pendataan
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container px={2} spacing={2} alignItems='flex-end'>
                            <Grid item xs={12} lg={4}>
                                <SoftBox mb={1}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Pilih Tanggal
                                    </SoftTypography>
                                </SoftBox>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker variant='outlined' format='YYYY-MM-DD' value={formData.tanggal} onChange={(newValue) => setFormData({ ...formData, tanggal: newValue })} disabled={formData.semua} sx={{ width: '100% !important' }} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} lg={4} ml={1.5}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={formData.semua} onChange={(event) => setFormData({ ...formData, semua: event.target.checked })} />} label="Semua Tanggal" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container px={2} spacing={2}>
                            <Grid item xs={12} lg={4}>
                                {/* <InputLabel id="demo-simple-select-label">Pilih Kabupaten</InputLabel> */}
                                <SelectKab formData={formData} setFormData={setFormData} disabled={true} />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectKec formData={formData} setFormData={setFormData} idKab={formData.id_kab} />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectDesa formData={formData} setFormData={setFormData} idKec={formData.id_kec} />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs={12}>
                                <TableData
                                    tableData={tableData}
                                    headers={['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
                                    fields={['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
                                    deleting={deleting}
                                    setDeleting={setDeleting}
                                    deleteUrl={serverUrl + '/admin/pendataan/delete'}
                                    deleteField='no_kk'
                                    detailUrl={'/detail'}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Pendataan