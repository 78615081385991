import React, { useEffect, useState } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import Tambah from './Tambah'
import Edit from './Edit'
import SoftBox from 'components/SoftBox'
import { Button, Card, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import { AddCircleOutline } from '@mui/icons-material'
import axios from 'axios'
import { serverUrl } from 'global/variable'
import { CustomDescrypt } from 'global/func'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SoftTypography from 'components/SoftTypography'
import Detail from './Detail'
import TableData from 'myComponent/TableData'

const Pendataan = () => {
    return (
        // <SoftBox py={3}>
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
            <Route exact path='tambah' element={<Tambah />} />
            <Route exact path='edit' element={<Edit />} />
        </Routes>
        // </SoftBox>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [tableData, setTableData] = useState([]);
    const [tanggal, setTanggal] = useState(dayjs(new Date()));
    const [semua, setSemua] = useState(false);
    const [deleting, setDeleting] = useState(false)
    const pathname = useLocation().pathname;


    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tanggal, semua, deleting])

    const requestData = () => {
        axios.post(
            serverUrl + '/tim/pendataan',
            {
                tgl: tanggal.format('YYYY-MM-DD'),
                semua: semua
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    const handleCheckbox = (event) => {
        setSemua(event.target.checked)
    }

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tableData.length}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Card >
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs="auto">
                                <Typography>
                                    Tabel Pendataan
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    endIcon={<AddCircleOutline color='white' />}
                                    // onClick={() => handleOpenDialog2()}
                                    to="/tim/pendataan/tambah"
                                    component={Link}
                                >
                                    Tambah
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container p={2} spacing={2} alignItems='flex-end'>
                            <Grid item litem xs={12} lg={4}>
                                <SoftBox mb={1}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Pilih Tanggal
                                    </SoftTypography>
                                </SoftBox>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker variant='outlined' format='YYYY-MM-DD' value={tanggal} onChange={(newValue) => setTanggal(newValue)} disabled={semua} sx={{ width: '100% !important' }} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} lg={4} ml={1.5}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={semua} onChange={handleCheckbox} />} label="Tampilkan Semua" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs={12}>
                                <TableData
                                    tableData={tableData}
                                    headers={['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
                                    fields={['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
                                    deleting={deleting}
                                    setDeleting={setDeleting}
                                    deleteUrl={serverUrl + '/tim/pendataan/delete'}
                                    deleteField='no_kk'
                                    detailUrl={'/detail'}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Pendataan