import { FormControl, MenuItem, Select } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import React, { useEffect, useState } from 'react'
import { serverUrl } from '../global/variable'

const SelectKab = ({formData, setFormData, disabled = false}) => {
    const [kabupatens, setKabupatens] = useState([]);

    useEffect(() => {
        getKab()
    }, [])

    const getKab = async () => {
        const kab = await axios.get(serverUrl + '/kabupaten');
        setKabupatens(kab.data)
        console.log('req kab');
    }

    const handleChangeKab = (event) => {
        // setKecs([])
        // setDesas([])
        if (event.target.value === '') {
            setFormData({ ...formData, id_kab: '', id_kec: '', id_desa: '', nama_kab: '', nama_kec: '', nama_desa: '' })
        }
        else {
            let resultObject = kabupatens.find(x => x.id_kab === event.target.value)
            setFormData({ ...formData, id_kab: event.target.value, nama_kab: resultObject.nama_kab })
            // getKec(event.target.value)
        }
    };

    return (
        <FormControl fullWidth sx={{ display: 'block !important' }}>
            <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Kabupaten / Kota
                </SoftTypography>
            </SoftBox>
            <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.id_kab}
                label="Kabupaten"
                onChange={handleChangeKab}
                required
                disabled={disabled}
            >
                <MenuItem value=''><em>Pilih Kabupaten</em></MenuItem>
                {
                    kabupatens.map((kab) => (
                        <MenuItem key={kab.id_kab} value={kab.id_kab}>{kab.nama_kab}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default SelectKab