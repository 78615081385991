import { Card, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import SoftBox from 'components/SoftBox';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material';
import Headerx from 'components/Header/Headerx';
import axios from 'axios';
import { serverUrl } from 'global/variable';
import Swal from 'sweetalert2';
import { CustomDescrypt } from 'global/func';
import userStore from 'store/userStore';
import DetailKeluarga from 'myComponent/DetailKeluarga';
import MapComponent from 'myComponent/MapComponent';

const Detail = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;
    const [gambar, setGambar] = useState([])

    useEffect(() => {
        reqGambar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reqGambar = () => {
        axios.post(
            serverUrl + '/dokumentasi',
            {
                no_kk: data.no_kk
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setGambar(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    // console.log(data);
    return (
        <>
            <Headerx title="Detail" />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <Typography variant='h6'>Kembali</Typography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            // subheader="September 14, 2016"
                            />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <Grid item xs={12}>
                                        <DetailKeluarga data={data} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MapComponent data={[data]} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} justifyContent='center' pt={2}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Dokumentasi
                                        </Typography>
                                    </Grid>
                                    {
                                        gambar.map((gbr, index) => (
                                            <Grid item xs={12} key={index}>
                                                <img
                                                    src={serverUrl + '/uploads/' + data.no_kk + '/' + gbr}
                                                    srcSet={serverUrl + '/uploads/' + data.no_kk + '/' + gbr}
                                                    alt={index}
                                                    loading="lazy"
                                                    width='100%'
                                                />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Detail